const QUAN_LY_TAI_KHOAN = "/quan-ly-tai-khoan"
const ROUTER = {
  SVG_VIEWER: "/svg-viewer",
  HOME: "/",
  TIN_TUC: "/tin-tuc",
  TAI_LIEU: "/tai-lieu",
  DONG_GOP: "/dop-gop",
  DIEN_TAP: "/dien-tap",
  DANG_NHAP: "/dang-nhap",
  DANG_KY: "/dang-ky",
  DOI_MAT_KHAU: "/doi-mat-khau",
  ACCOUNT: "/tai-khoan",
  //ANONYMOUS

  // ADMIN
  QUAN_LY_TAI_KHOAN: "/quan-ly-tai-khoan",
  QUAN_LY_DIEN_TAP: `${QUAN_LY_TAI_KHOAN}/quan-ly-tai-lieu`,
  TIEU_CHI_DANH_GIA: `${QUAN_LY_TAI_KHOAN}/tieu-chi-danh-gia`,
  DONG_GOP_Y_KIEN: `${QUAN_LY_TAI_KHOAN}/dong-gop-y-kien`,
  QUAN_LY_TAI_LIEU: `${QUAN_LY_TAI_KHOAN}/quan-ly-tai-lieu`,
  QUAN_LY_FORM_MAU: `${QUAN_LY_TAI_KHOAN}/quan-ly-form-mau`,
  DANH_BA_NGUOI_DUNG: `${QUAN_LY_TAI_KHOAN}/danh-ba-nguoi-dung`,
  PHAN_QUYEN: `${QUAN_LY_TAI_KHOAN}/phan-quyen`,
  LICH_SU_HOAT_DONG: `${QUAN_LY_TAI_KHOAN}/lich-su-hoat-dong`,
  DASHBOARD: `${QUAN_LY_TAI_KHOAN}/dashboard`,
  CHI_TIET: `${QUAN_LY_TAI_KHOAN}/chi-tiet`,
}
export default ROUTER
